<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12">
      <app-basic-input
        label="Name"
        [placeholder]="'Enter Package Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <!-- <div class="col-12">
    <app-drop-down-input [label]="'Module'"
      [placeholder]="'Select Item'"
      [name]="'dropdown'"
      [optionLabel]="'label'"
      [optionValue]="'value'"
      [items]="modules"
      [control]="formGroup?.controls?.module"
      [viewMode]="fieldViewMode"
      [badgeView]="true"
    [multi]="false"></app-drop-down-input>
  </div> -->
    <div class="col-12">
      <app-drop-down-input
        [label]="'Types to Include'"
        [placeholder]="'Select Item'"
        [name]="'dropdown'"
        [optionLabel]="'label'"
        [optionValue]="'value'"
        [items]="listOfTypesToInclude"
        [control]="formGroup?.controls?.typesToInclude"
        [viewMode]="fieldViewMode"
        [badgeView]="true"
        [multi]="true"
        [loading]="isLoadingTypes"
      >
        <!-- <ng-template let-items #selectedItemTemplate>
          <div class="flex flex-row gap-1 align-items-center flex-wrap">
            @for (item of items; track item) {
              @if (item?.label) {
                <span>
                  {{ item?.label }}
                </span>
              }
            }
          </div>
        </ng-template> -->
      </app-drop-down-input>
    </div>
    <div class="col-12">
      <app-target-code-selector
        [label]="'Selected Targets'"
        [placeholder]="'Search Targets...'"
        [control]="formGroup?.controls?.codesToExport"
        [viewMode]="fieldViewMode"
        [inlineMode]="false"
        [appendTo]="'body'"
        [multi]="true"
        [tableMode]="true"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [customProjectionFields]="['code', 'name', 'recordStatus']"
        [targetTypes]="selectedTypedToInclude"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-switch-input
        [label]="'Export Manual Relations'"
        [name]="'exportManualRelations'"
        [control]="formGroup?.controls?.exportManualRelations"
        [viewMode]="fieldViewMode"
      >
      </app-switch-input>
    </div>
    <div class="col-12 md:col-6">
      <app-switch-input
        [label]="'Export Reference Relations'"
        [name]="'exportReferenceRelations'"
        [control]="formGroup?.controls?.exportReferenceRelations"
        [viewMode]="fieldViewMode"
      >
      </app-switch-input>
    </div>
    <div class="col-12">
      <app-text-editor
        [height]="'115px'"
        [name]="'description'"
        label="Package Description"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
      ></app-text-editor>
    </div>
  </div>
  <!-- <ng-content></ng-content> -->
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      <!-- <app-button *ngIf="showSaveButton"
  [action]="submitSaveButtonAction"></app-button> -->
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
